import pilot1 from '../images/pilot1.png'
import pilot2 from '../images/pilot2.png'
import pilot3 from '../images/pilot3.png'
import pilot4 from '../images/pilot4.png'
import pilot5 from '../images/pilot5.png'
import pilot6 from '../images/pilot6.png'
import pilot7 from '../images/pilot7.png'
import pilot8 from '../images/pilot8.png'

export const PILOTS = [
  pilot1,
  pilot2,
  pilot3,
  pilot4,
  pilot5,
  pilot6,
  pilot7,
  pilot8,
];